import ReactConfetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

interface Props {
  onFinish: () => void
}

export const Confetti: React.FC<Props> = ({ onFinish }) => {
  const { width, height } = useWindowSize()
  return (
    <ReactConfetti
      width={width}
      height={height}
      colors={['#D6CFFE', '#FDD5D5', '#C5F596', '#DAEBFF', '#FEDAAE', '#FFF5A2']}
      recycle={false}
      numberOfPieces={300}
      tweenDuration={10}
      onConfettiComplete={onFinish}
    />
  )
}
