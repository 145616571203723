import { useState, ReactNode } from 'react'
import clsx from 'classnames'

interface AccordionProps {
  title: string
  children: ReactNode
}

export const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="mb-4 rounded-2xl bg-white">
      <button
        type="button"
        onClick={toggleAccordion}
        className={clsx(
          'bg-white-100 flex w-full items-center justify-between rounded-t-2xl p-4 text-left focus:outline-none',
        )}
      >
        <span className="font-bold text-gray-800">{title}</span>
        <svg
          className={clsx('h-6 w-6 transition-transform duration-200', {
            'rotate-180 transform': isOpen,
          })}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && <div className="rounded-b-2xl bg-white p-4 text-gray-700">{children}</div>}
    </div>
  )
}
