import clsx from 'classnames'

interface Props {
  emoji: string
  text: string
  subtext?: string
  color: string
  onClick: () => void
  selected?: boolean
}

export const Button: React.FC<Props> = ({
  color,
  emoji,
  onClick,
  selected = false,
  subtext,
  text,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        `h-[75px] from-${color} via-${color} pointer-cursor w-full transform rounded-full border border-[#9699AE40] bg-white bg-gradient-to-r from-40% to-white to-65% bg-[length:200%_100%] pl-6 pr-10 text-left drop-shadow transition-all hover:opacity-80`,
        selected ? 'bg-[0%_100%]' : 'bg-[100%_100%]',
      )}
    >
      <div className="flex items-center space-x-4">
        <span className="text-3xl shadow-[#0000002E] text-shadow">{emoji}</span>

        <div className="flex flex-col">
          <span className="font-semibold leading-4">{text}</span>

          {subtext && <span className="text-sm">{subtext}</span>}
        </div>
      </div>
    </button>
  )
}
