import clsx from 'classnames'
import React, { useState } from 'react'

interface Props {
  invalid?: boolean
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  required?: boolean
  showEye?: boolean
  type: string
  value: string
}

const HidePasswordIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.72152 2.5L2.34753 3.85157L20.0284 21.25L21.4023 19.898L3.72152 2.5V2.5Z"
      fill="#BBBFCB"
    />
    <path
      d="M5.2504 6.70787L6.54365 7.98043C5.12795 9.16654 4.00064 10.643 3.27216 11.9347L3.26994 11.9391C5.29876 15.277 8.51789 18.4315 12.5808 17.9531C13.6776 17.8239 14.7006 17.455 15.6354 16.9272L16.9446 18.215C15.1225 19.3658 13.0338 19.9752 10.7708 19.674C6.69005 19.1309 3.35335 15.8375 1.25 11.9697C2.23757 10.0143 3.58848 8.1437 5.2504 6.70787V6.70787ZM8.19181 4.82196C9.33288 4.32167 10.5676 4.02743 11.8861 4.00647C11.9593 4.00604 12.8284 4.04664 13.2224 4.11037C13.4695 4.15054 13.7157 4.20074 13.9584 4.26317C17.8292 5.25545 20.697 8.47548 22.5 11.7929C21.7436 13.2959 20.7529 14.7627 19.5715 16.0196L18.3173 14.7854C19.1899 13.8599 19.9175 12.8297 20.4778 11.8304C20.4778 11.8304 19.9144 10.939 19.4872 10.3793C19.2125 10.0196 18.9237 9.67033 18.6203 9.33374C18.3807 9.06832 17.4504 8.16683 17.229 7.97912C15.7436 6.72228 13.9996 5.7361 11.9078 5.75269C11.0977 5.76535 10.3187 5.92251 9.57911 6.18706L8.19181 4.82196V4.82196Z"
      fill="#BBBFCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.77076 10.1725L9.76911 11.1545L13.6119 14.9357C13.0981 15.2195 12.5054 15.381 11.875 15.381C9.91183 15.381 8.31824 13.8129 8.31824 11.8812C8.31824 11.2608 8.48283 10.678 8.77076 10.1725ZM11.8093 8.38219C11.8311 8.38175 11.8532 8.38132 11.875 8.38132C13.8381 8.38132 15.4317 9.94986 15.4317 11.8812C15.4317 11.903 15.4317 11.9244 15.4313 11.9458L11.8093 8.38219Z"
      fill="#BBBFCB"
    />
  </svg>
)
const ShowPasswordIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0325 3.7501C16.7237 3.81772 20.5723 8.1031 22.5 11.7903C22.5 11.7903 21.8217 13.2008 21.1855 14.1036C20.8776 14.5404 20.5537 14.9659 20.2134 15.3775C19.9707 15.6705 19.7201 15.9558 19.4601 16.233C17.1367 18.7122 13.8 20.5617 10.2765 19.8436C6.36219 19.0458 3.16923 15.638 1.25 11.9729C1.25 11.9729 1.93145 10.5611 2.57119 9.65958C2.85779 9.25524 3.15859 8.86173 3.47358 8.47994C3.71493 8.18739 3.96514 7.90206 4.22379 7.62484C6.27657 5.42693 8.91052 3.73478 12.0325 3.7501V3.7501ZM12.0161 5.55315C9.40298 5.54323 7.22997 7.02578 5.51083 8.86624C5.27702 9.11641 5.05165 9.37425 4.83337 9.63839C4.54633 9.98638 4.27215 10.3456 4.01084 10.7139C3.74953 11.0817 3.48157 11.546 3.26906 11.9404C4.96114 14.8294 7.50104 17.4388 10.6257 18.0757C13.5374 18.6694 16.2547 17.0385 18.1748 14.9898C18.4095 14.7397 18.6362 14.4814 18.8554 14.2168C19.166 13.8413 19.4614 13.4532 19.7423 13.0543C20.0022 12.6851 20.2698 12.2199 20.4818 11.825C18.7259 8.83874 15.7215 5.61175 12.0161 5.55315Z"
      fill="#BBBFCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.875 8.26779C13.8381 8.26779 15.4322 9.88738 15.4322 11.8815C15.4322 13.8762 13.8381 15.4953 11.875 15.4953C9.91228 15.4953 8.31824 13.8762 8.31824 11.8815C8.31824 9.88738 9.91228 8.26779 11.875 8.26779Z"
      fill="#BBBFCB"
    />
  </svg>
)

export const Input: React.FC<Props> = ({
  invalid,
  onBlur,
  onChange,
  placeholder,
  required = false,
  showEye = false,
  type,
  value,
}) => {
  const [showPassword, setShowPassword] = useState(() => type !== 'password')

  return (
    <div className="relative">
      <input
        className={clsx(
          'w-full rounded-full border p-7 font-semibold',
          invalid ? 'border-[#FF0000] outline-[#FF0000]' : 'border-[#BBBFCB] outline-[#BBBFCB]',
        )}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        value={value}
      />
      {showEye && (
        <button
          className="absolute right-[30px] top-[29px]"
          onClick={() => setShowPassword((prev) => !prev)}
          type="button"
        >
          {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
        </button>
      )}
    </div>
  )
}
