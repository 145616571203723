import { LoadingSpinner } from 'components/LoadingSpinner'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const BuildingPlan = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(RoutePaths.Plans)
    }, 5000)
    return () => clearTimeout(timeout)
  }, [navigate])

  return (
    <div className="flex w-full flex-col space-y-8">
      <h1 className="text-2xl font-bold">{t('buildingPlan.headline')}</h1>

      <div className="flex grow items-center">
        <div className="mx-auto w-2/3 space-y-9 rounded-xl bg-black px-9 py-20 text-white shadow-[0_25px_30px_-10px_rgba(38,57,77,0.6)]">
          <LoadingSpinner className="mx-auto" />

          <p className="text-center text-2xl font-bold">{t('buildingPlan.cardText')}</p>
        </div>
      </div>
    </div>
  )
}
