import { StripePrice } from 'types'

export default function getCurrencyAndPriceInCents({
  stripePrice,
  userCurrency,
}: {
  stripePrice: StripePrice
  userCurrency: string
}): { currency: string; priceInCents: number } {
  if (!stripePrice) return { currency: '', priceInCents: 0 }

  const unitAmount = stripePrice?.unit_amount_decimal
  const unitAmountInCurrency =
    stripePrice?.currency_options?.[userCurrency.toLowerCase()]?.unit_amount_decimal

  const currency = unitAmountInCurrency ? userCurrency : stripePrice.currency
  const priceInCents = parseInt(unitAmountInCurrency || unitAmount)

  return { currency, priceInCents }
}
