import { ContinueButton } from 'components/ContinueButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'
import reviewImage from 'assets/review.jpg'

export const Review = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleContinue = () => {
    navigate(RoutePaths.TimeOfDay)
  }

  return (
    <div className="flex w-full flex-col items-center justify-between">
      <div className="space-y-8">
        <div className="space-y-4">
          <h1 className="text-2xl font-bold">{t('review.headline')}</h1>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <img src={reviewImage} alt="Review" className="h-auto w-80 rounded-lg" />
          <span className="text-center text-3xl">⭐️⭐️⭐️⭐️⭐️</span>
          <h3
            className="text-center text-2xl italic"
            dangerouslySetInnerHTML={{
              __html: t('review.text', { interpolation: { escapeValue: false } }),
            }}
          />
        </div>
      </div>
      <div className="mb-4 mt-6">
        <ContinueButton onClick={handleContinue} disabled={false} />
      </div>
    </div>
  )
}
