import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  Route,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { Layout } from 'components/Layout'
import { RoutePaths } from 'routes'

import { getUserId } from 'api/supabase'
import { getIsPremiumEntitled } from 'helpers/subscriptions'
import { BuildingPlan } from 'pages/BuildingPlan'
import { Checkout } from 'pages/Checkout'
import { Desire } from 'pages/Desire'
import { Distractions } from 'pages/Distractions'
import { Drivers } from 'pages/Drivers'
import { Email } from 'pages/Email'
import { Feedback } from 'pages/Feedback'
import { FeedbackFeeling } from 'pages/FeedbackFeeling'
import { Feeling } from 'pages/Feeling'
import { ForgotPassword } from 'pages/ForgotPassword'
import { Goals } from 'pages/Goals'
import { Habits } from 'pages/Habits'
import { LastMinute } from 'pages/LastMinute'
import { Login } from 'pages/Login'
import { PartOfDay } from 'pages/PartOfDay'
import { Password } from 'pages/Password'
import PaymentConfirmation from 'pages/PaymentConfirmation'
import { Plans } from 'pages/Plans'
import { Ready } from 'pages/Ready'
import { ResetPassword } from 'pages/ResetPassword'
import { Review } from 'pages/Review'
import { StatementAnxious } from 'pages/StatementAnxious'
import { StatementRegrets } from 'pages/StatementRegrets'
import { ThankYou } from 'pages/ThankYou'
import { TimeOfDay } from 'pages/TimeOfDay'
import { IsPremiumEntitledAndIsSignedIn, IsSignedIn } from 'types'
import { ProtectedRoute } from './ProtectedRoute'
import { RedirectRoute } from './RedirectRoute'
import RefundGooglePage from 'pages/helpCenter/RefundGooglePage'
import CancelationPage from 'pages/helpCenter/CancelationPage'
import RefundPage from 'pages/helpCenter/RefundPage'
import RefundApplePage from 'pages/helpCenter/RefundApplePage'
import RefundCompletedPage from 'pages/helpCenter/RefundCompletedPage'
import CancelationGooglePage from 'pages/helpCenter/CancelationGooglePage'
import CancelationCompletedPage from 'pages/helpCenter/CancelationCompletedPage'
import CancelationApplePage from 'pages/helpCenter/CancelationApplePage'
import HelpCenterPage from 'pages/helpCenter/HelpCenterPage'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
})

async function loadIsSignedIn(): Promise<IsSignedIn> {
  const userId = await getUserId()

  return { isSignedIn: !!userId }
}

async function loadIsPremiumEntitledAndIsSignedIn(): Promise<IsPremiumEntitledAndIsSignedIn> {
  const userId = await getUserId()
  const isPremiumEntitled = userId ? await getIsPremiumEntitled() : false

  return { isPremiumEntitled, isSignedIn: !!userId }
}

export const router = () => {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  return sentryCreateBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={RoutePaths.Root} element={<Layout />}>
          <Route path={RoutePaths.ForgotPassword} element={<ForgotPassword />} />
          <Route path={RoutePaths.Login} element={<Login />} />
          <Route path={RoutePaths.Drivers} element={<Drivers />} loader={loadIsSignedIn} />
          <Route path={RoutePaths.Goals} element={<Goals />} />
          <Route path={RoutePaths.Feedback} element={<Feedback />} />
          <Route path={RoutePaths.LastMinute} element={<LastMinute />} />
          <Route path={RoutePaths.Distractions} element={<Distractions />} />
          <Route path={RoutePaths.PartOfDay} element={<PartOfDay />} />
          <Route path={RoutePaths.Review} element={<Review />} />
          <Route path={RoutePaths.TimeOfDay} element={<TimeOfDay />} />
          <Route path={RoutePaths.Desire} element={<Desire />} />
          <Route path={RoutePaths.Feeling} element={<Feeling />} />
          <Route path={RoutePaths.FeedbackFeeling} element={<FeedbackFeeling />} />
          <Route path={RoutePaths.StatementAnxious} element={<StatementAnxious />} />
          <Route path={RoutePaths.StatementRegrets} element={<StatementRegrets />} />
          <Route path={RoutePaths.Habits} element={<Habits />} />
          <Route path={RoutePaths.Ready} element={<Ready />} />
          <Route path={RoutePaths.Password} element={<Password />} />
          <Route path={RoutePaths.Email} element={<Email />} />


          <Route
            path={RoutePaths.Root}
            element={<RedirectRoute />}
            loader={loadIsPremiumEntitledAndIsSignedIn}
          /> 
                    {/* <Route path={RoutePaths.HelpCenter} element={<HelpCenterPage />} /> */}
         
          <Route element={<ProtectedRoute />} loader={loadIsSignedIn}>
            <Route path={RoutePaths.BuildingPlan} element={<BuildingPlan />} />
            <Route path={RoutePaths.Checkout} element={<Checkout />} />
            <Route path={RoutePaths.PaymentConfirmation} element={<PaymentConfirmation />} />
            <Route path={RoutePaths.ResetPassword} element={<ResetPassword />} />
            <Route path={RoutePaths.ThankYou} element={<ThankYou />} />
          </Route>

          <Route path={RoutePaths.HelpCenter} element={<HelpCenterPage />} />
          <Route path={RoutePaths.Cancelation} element={<CancelationPage />} />
          <Route path={RoutePaths.CancelationApple} element={<CancelationApplePage />} />
          <Route path={RoutePaths.CancelationGoogle} element={<CancelationGooglePage />} />
          <Route path={RoutePaths.CancelationCompleted} element={<CancelationCompletedPage />} />

          <Route path={RoutePaths.Refund} element={<RefundPage />} />
          <Route path={RoutePaths.RefundApple} element={<RefundApplePage />} />
          <Route path={RoutePaths.RefundGoogle} element={<RefundGooglePage />} />
          <Route path={RoutePaths.RefundCompleted} element={<RefundCompletedPage />} />
       
        </Route>,
     

        ,{/* NOTE: The plans page needs its custom layout */}
        <Route element={<ProtectedRoute />} loader={loadIsSignedIn}>
          <Route path={RoutePaths.Plans} element={<Plans />} />,
        </Route>
      </>,
    ),
  )
}
