import perfectDayLogoUrl from 'assets/perfect-day-logo.png'
import clsx from 'classnames'
import { Confetti } from 'components/Confetti'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const Ready = () => {
  const [checked, setChecked] = useState(false)

  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      {checked && <Confetti onFinish={() => navigate(RoutePaths.Password)} />}

      <div className="flex w-full flex-col space-y-8">
        <div className="basis-2/5 space-y-4">
          <h1 className="text-2xl font-bold">{t('ready.headline')}</h1>
        </div>

        <div className="flex basis-3/5 flex-col justify-between space-y-6">
          <div>
            <div className="z-10 w-full rounded-full border border-[#9699AE40] bg-gradient-to-r from-pd-green to-white to-25% px-6 py-4 drop-shadow">
              <div className="flex items-center justify-between space-x-4 sm:space-x-16">
                <img
                  src={perfectDayLogoUrl}
                  alt="Perfect Day logo"
                  className="h-[40px] w-[33px] shrink-0"
                />

                <span
                  className={clsx('grow font-semibold leading-4', checked ? 'line-through' : '')}
                >
                  {t('ready.text')}
                </span>

                <button
                  onClick={() => setChecked(true)}
                  className={clsx(
                    'relative h-[30px] w-[30px] shrink-0 items-center justify-center rounded-full border border-x-[1.8px] border-y-[1.8px] transition-all',
                    checked ? 'border-[#222222] bg-[#222222]' : 'border-[#BBBFCB] bg-transparent',
                  )}
                >
                  {checked && (
                    <svg
                      className="ml-[7px]"
                      fill="none"
                      height="13"
                      viewBox="0 0 13 13"
                      width="13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.219 11.882C6.14124 11.8979 6.06096 11.8976 5.98277 11.8811C5.90457 11.8647 5.82998 11.8324 5.76327 11.7861L1.21684 8.6354C1.08208 8.54201 0.987526 8.39733 0.953984 8.23318C0.937376 8.1519 0.936245 8.06829 0.950657 7.98711C0.965069 7.90594 0.994742 7.8288 1.03798 7.76009C1.08122 7.69138 1.13718 7.63245 1.20266 7.58667C1.26814 7.54088 1.34187 7.50914 1.41962 7.49325C1.57666 7.46116 1.74059 7.4956 1.87535 7.58898L5.91366 10.3876L11.5792 1.38442C11.667 1.24681 11.8041 1.15193 11.9606 1.12049C12.1171 1.08904 12.2803 1.12359 12.4145 1.21658C12.5487 1.30958 12.643 1.45347 12.6769 1.61685C12.7108 1.78023 12.6815 1.94981 12.5954 2.08859L6.60063 11.6151C6.55741 11.6838 6.50146 11.7427 6.43598 11.7885C6.3705 11.8343 6.29676 11.8661 6.219 11.882Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                      />
                    </svg>
                  )}

                  <svg
                    className="absolute right-[30px] top-[40px]"
                    width="59"
                    height="67"
                    viewBox="0 0 59 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M53.3509 2.84655C49.6889 3.54603 46.1778 4.2318 42.6667 4.90384C41.9672 5.041 41.4734 5.137 40.5682 5.16444C39.8962 5.19187 38.4698 5.2193 38.5932 4.49239C38.7578 3.90263 38.9498 3.50489 40.1431 3.14829C43.229 2.24308 46.4247 1.80419 49.5929 1.35159C52.3908 0.953845 54.5304 0.693254 57.0266 0.487525C58.1924 0.391518 58.6587 0.96756 58.5215 2.03735C58.3981 2.99742 58.2884 3.98492 57.9866 4.90384C56.9168 8.22294 55.7648 11.5009 54.6401 14.8063C54.503 15.2177 54.4207 15.5469 54.1875 16.0132C53.8995 16.5755 53.7212 17.0007 53.1314 16.8361C52.5691 16.6853 52.5965 15.8624 52.6925 15.4509C53.4194 11.9946 55.0378 4.89013 55.0378 4.49239C53.2411 5.64447 51.5542 6.67312 49.9221 7.79777C38.5932 15.6841 27.9227 24.311 19.433 35.3655C12.7125 44.1158 7.1441 53.5108 3.02952 63.7561C2.78264 64.3596 2.48091 64.9493 2.17917 65.5117C1.78143 66.2797 1.43854 66.6089 1.02709 66.4306C0.300176 65.9917 0.437329 65.4019 0.505905 64.9219C0.629343 63.9618 0.95851 63.0155 1.27396 62.0966C5.6217 49.8488 12.5342 39.1235 20.8868 29.2622C29.4589 19.1403 39.9373 11.35 51.1153 4.42381C51.7188 4.08093 53.1314 3.09343 53.3509 2.84655Z"
                      fill="#222222"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="mx-12 mt-16 sm:float-right">
              <p className="whitespace-pre-line text-center text-sm font-bold text-[#BBBFCB]">
                {t('ready.subtext')}
              </p>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            'absolute -bottom-10 left-[50%] right-[50%] z-10 w-full -translate-x-[50%] text-center transition-transform',
            checked ? '-translate-y-20' : '',
          )}
        >
          <Link className="text-center text-3xl font-bold underline" to={RoutePaths.Password}>
            {t('ready.letsgo')}
          </Link>
        </div>
      </div>
    </>
  )
}
