import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const LOCALES = [
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'fr',
  'hi',
  'hr',
  'hu',
  'it',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sv',
  'zh',
]

const loadResources = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = {}

  const jsonTranslationModules = import.meta.glob('./locales/*/common.json', {
    eager: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as Record<string, { default: any }>

  LOCALES.forEach((locale) => {
    const translation = jsonTranslationModules[`./locales/${locale}/common.json`]?.default || {}

    result[locale] = {
      translation: {
        ...translation,
      },
    }
  })

  return result
}

const resources = loadResources()

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    prefix: '%{',
    suffix: '}',
  },
})

export default i18n
