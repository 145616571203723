import { Dialog } from '@headlessui/react'
import { CTAButton } from 'components/CTAButton'
import { Modal } from 'components/Modal'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  text: string
  buttonText?: string
  onButtonClick?: () => void
}

export const TextModal: React.FC<Props> = ({ open, setOpen, text, buttonText, onButtonClick }) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="mt-3 text-left sm:mt-5">
        <Dialog.Title as="h3" className="text-2xl font-bold leading-7">
          {text}
        </Dialog.Title>
      </div>

      {buttonText && onButtonClick && (
        <div className="mt-8">
          <CTAButton text={buttonText} onClick={onButtonClick} />
        </div>
      )}
    </Modal>
  )
}
