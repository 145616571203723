import { Button } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const Distractions = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-4">
        <h1 className="text-2xl font-bold">{t('distractions.headline')}</h1>
      </div>

      <div className="shrink-0 space-y-6">
        <Button
          color="pd-blue"
          emoji="🥷"
          onClick={() => navigate(RoutePaths.PartOfDay)}
          text={t('distractions.distraction_1')}
        />

        <Button
          color="pd-peach"
          emoji="😎"
          onClick={() => navigate(RoutePaths.PartOfDay)}
          text={t('distractions.distraction_2')}
        />

        <Button
          color="pd-yellow"
          emoji="🤔"
          onClick={() => navigate(RoutePaths.PartOfDay)}
          text={t('distractions.distraction_3')}
        />

        <Button
          color="pd-green"
          emoji="🎈"
          onClick={() => navigate(RoutePaths.PartOfDay)}
          text={t('distractions.distraction_4')}
        />
      </div>
    </div>
  )
}
