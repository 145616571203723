import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import LayoutWhite from "../../components/LayoutWhite";
import { RefundRevenueCatSubscriptionsData } from "supabase_types";

export default function RefundCompletedPage() {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    activeAppStoreSubscriptionsCount,
    activePlayStoreSubscriptionsCount,
    canceledStripeSubscriptionsCount,
    refundedStripeSubscriptionsCount,
  } = (location.state as RefundRevenueCatSubscriptionsData) || {};

  const appleUrl = "https://support.apple.com/118223";

  const googleUrl = "https://support.google.com/googleplay/workflow/9813244";

  const hasAppleSubscription = activeAppStoreSubscriptionsCount > 0;

  const hasGoogleSubscription = activePlayStoreSubscriptionsCount > 0;

  const hasAppleAndGoogleSubscriptions =
    hasAppleSubscription && hasGoogleSubscription;

  const hasAppleOrGoogleSubscription =
    hasAppleSubscription || hasGoogleSubscription;

  const hasCanceledWebSubscription = canceledStripeSubscriptionsCount > 0;

  const hasRefundedWebSubscription = refundedStripeSubscriptionsCount > 0;

  const followGuidelinesTranslation = () => {
    if (hasAppleAndGoogleSubscriptions)
      return t(
        "pages.helpCenter.refundCompleted.followGoogleAndAppleGuidelines",
      );

    if (hasAppleSubscription)
      return t("pages.helpCenter.refundCompleted.followAppleGuidelines");

    return t("pages.helpCenter.refundCompleted.followGoogleGuidelines");
  };

  const regardingSubscriptionTranslation = () => {
    if (hasAppleAndGoogleSubscriptions)
      return t("pages.helpCenter.regardingGoogleAndAppleSubscriptions");

    if (hasGoogleSubscription)
      return t("pages.helpCenter.regardingGoogleSubscription");

    return t("pages.helpCenter.regardingAppleSubscription");
  };

  if (hasCanceledWebSubscription && !hasRefundedWebSubscription)
    return (
      <LayoutWhite>
        <div className="flex h-full flex-col items-center justify-center space-y-8 text-center">
          <ExclamationTriangleIcon className="text-help-center-warning h-20" />

          <p className="text-2xl font-bold">
            {t("pages.helpCenter.weAreSorry")}
          </p>

          <p>
            <Trans
              components={{
                boldError: (
                  <span className="font-bold text-help-center-error" />
                ),
                boldSuccess: (
                  <span className="font-bold text-help-center-success" />
                ),
              }}
              i18nKey="pages.helpCenter.refundCompleted.subscriptionCouldNotBeRefunded"
            />
          </p>
        </div>
      </LayoutWhite>
    );

  if (!hasAppleOrGoogleSubscription) {
    return (
      <LayoutWhite>
        <div className="flex h-full flex-col items-center justify-center space-y-8 text-center">
          <CheckCircleIcon className="h-20 text-help-center-success" />

          <p className="text-2xl font-bold">{t("pages.helpCenter.thankYou")}</p>

          <p>
            <Trans
              components={{
                bold: <span className="font-bold text-help-center-success" />,
              }}
              i18nKey="pages.helpCenter.refundCompleted.subscriptionSuccessfullyCanceledAndRefunded"
            />
          </p>
        </div>
      </LayoutWhite>
    );
  }

  return (
    <LayoutWhite>
      <div className="flex flex-col items-center justify-center space-y-8 text-center">
        <p className="text-2xl font-bold">
          {t("pages.helpCenter.shared.furtherActionNeeded")}
        </p>

        {hasRefundedWebSubscription && (
          <p>
            <Trans
              components={{
                bold: <span className="font-bold text-help-center-success" />,
              }}
              i18nKey="pages.helpCenter.refundCompleted.webSubscriptionCanceledAndRefunded"
            />
          </p>
        )}

        {hasAppleOrGoogleSubscription && (
          <>
            <p className="font-bold">{regardingSubscriptionTranslation()}</p>

            <p>
              {followGuidelinesTranslation()}{" "}
              <span className="font-bold">
                {t(
                  "pages.helpCenter.refundCompleted.followInstructionsToRefund",
                )}
              </span>
            </p>
          </>
        )}

        {hasGoogleSubscription && (
          <div className="space-y-2">
            <p className="font-bold">Google:</p>

            <div className="w-full rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center sm:w-auto">
              <Link
                className="break-all underline"
                target="_blank"
                to={googleUrl}
              >
                {googleUrl}
              </Link>
            </div>
          </div>
        )}

        {hasAppleSubscription && (
          <div className="space-y-2">
            <p className="font-bold">Apple:</p>

            <div className="w-full rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center sm:w-auto">
              <Link
                className="break-all underline"
                target="_blank"
                to={appleUrl}
              >
                {appleUrl}
              </Link>
            </div>
          </div>
        )}
      </div>
    </LayoutWhite>
  );
}
