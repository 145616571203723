import * as Sentry from '@sentry/react'
import { supabaseClient } from 'api/supabase'
import { Input } from 'components/Input'
import { SignInWithButton } from 'components/SignInWithButton'
import { TextModal } from 'components/TextModal'
import { isEmailValid, isPasswordValid } from 'helpers/validators'
import { useState } from 'react'
import { isAndroid } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const Login = () => {
  const [email, setEmail] = useState<string>('')
  const [emailValid, setEmailValid] = useState<boolean>(true)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [passwordValid, setPasswordValid] = useState<boolean>(true)

  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const supabase = supabaseClient

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    })

    if (error) {
      if (error.message === 'Invalid login credentials') {
        setModalOpen(true)
      } else {
        Sentry.captureException(error)
      }
    } else {
      const searchParams = new URLSearchParams(location.search);
      const path = searchParams.get("path");
      navigate(path || location.state?.from || RoutePaths.Root)
    }
  }

  const handleSignInWithApple = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'apple',
    })
  }

  return (
    <>
      <div className="flex w-full flex-col justify-between space-y-9">
        <h1 className="mb-11 text-2xl font-bold">{t('login.headline')}</h1>

        <form className="space-y-11" onSubmit={handleSignIn}>
          <Input
            invalid={!emailValid}
            onBlur={() => setEmailValid(isEmailValid(email))}
            onChange={(event) => setEmail(event?.target.value)}
            placeholder={t('common.email')}
            required
            type="email"
            value={email}
          />

          <div>
            <Input
              invalid={!passwordValid}
              onBlur={() => setPasswordValid(isPasswordValid(password))}
              onChange={(event) => setPassword(event?.target.value)}
              placeholder={t('common.password')}
              required
              showEye
              type="password"
              value={password}
            />

            <Link
              className="float-right mt-1 text-xs font-bold underline underline-offset-1"
              to={RoutePaths.ForgotPassword}
            >
              {t('login.forgotPassword')}
            </Link>
          </div>

          <button
            type="submit"
            className="inline-flex w-full justify-center rounded-full bg-[#222222] px-3 py-6 text-xl font-bold text-white shadow-[0_25px_30px_-10px_rgba(38,57,77,0.6)] focus-visible:outline-none"
          >
            {t('login.logIn')}
          </button>
        </form>

        <div className="flex flex-col gap-4">
          <SignInWithButton
            className={isAndroid ? 'order-2' : 'order-1'}
            onClick={handleSignInWithApple}
            provider="Apple"
          />
        </div>

        <div className="text-center">
          <Link className="text-lg font-bold underline" to={RoutePaths.Root}>
            {t('login.signUp')}
          </Link>
        </div>
      </div>

      {modalOpen && (
        <TextModal
          buttonText={t('login.modalButton')}
          onButtonClick={() => navigate(RoutePaths.ForgotPassword)}
          open
          setOpen={setModalOpen}
          text={t('login.modalText')}
        />
      )}
    </>
  )
}
