export const LoadingSpinner = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        id="Rectangle 1"
        x="22.429"
        y="0.357788"
        width="4"
        height="12"
        rx="2"
        fill="white"
        className="animate-[mild-pulse_2s_cubic-bezier(0.4,0,0.6,1)_0s_infinite]"
      />
      <rect
        id="Rectangle 2"
        x="34.3284"
        y="17.2866"
        width="4"
        height="12"
        rx="2"
        transform="rotate(-135 34.3284 17.2866)"
        fill="white"
        className="animate-[mild-pulse_2s_cubic-bezier(0.4,0,0.6,1)_0.25s_infinite]"
      />
      <rect
        id="Rectangle 3"
        x="36.429"
        y="26.3578"
        width="4"
        height="12"
        rx="2"
        transform="rotate(-90 36.429 26.3578)"
        fill="white"
        className="animate-[mild-pulse_2s_cubic-bezier(0.4,0,0.6,1)_0.5s_infinite]"
      />
      <rect
        id="Rectangle 4"
        x="31.5"
        y="34.2573"
        width="4"
        height="12"
        rx="2"
        transform="rotate(-45 31.5 34.2573)"
        fill="white"
        className="animate-[mild-pulse_2s_cubic-bezier(0.4,0,0.6,1)_0.75s_infinite]"
      />
      <rect
        id="Rectangle 5"
        x="22.429"
        y="36.3578"
        width="4"
        height="12"
        rx="2"
        fill="white"
        className="animate-[mild-pulse_2s_cubic-bezier(0.4,0,0.6,1)_1s_infinite]"
      />
      <rect
        id="Rectangle 6"
        x="8.87256"
        y="42.7426"
        width="4"
        height="12"
        rx="2"
        transform="rotate(-135 8.87256 42.7426)"
        fill="white"
        className="animate-[mild-pulse_2s_cubic-bezier(0.4,0,0.6,1)_1.25s_infinite]"
      />
      <rect
        id="Rectangle 7"
        x="0.428955"
        y="26.3578"
        width="4"
        height="12"
        rx="2"
        transform="rotate(-90 0.428955 26.3578)"
        fill="white"
        className="animate-[mild-pulse_2s_cubic-bezier(0.4,0,0.6,1)_1.5s_infinite]"
      />
      <rect
        id="Rectangle 8"
        x="6.04419"
        y="8.80139"
        width="4"
        height="12"
        rx="2"
        transform="rotate(-45 6.04419 8.80139)"
        fill="white"
        className="animate-[mild-pulse_2s_cubic-bezier(0.4,0,0.6,1)_1.75s_infinite]"
      />
    </svg>
  )
}
