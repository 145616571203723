import clsx from 'classnames'
import React from 'react'

interface Props {
  disabled: boolean
  onClick?: () => void
}

export const ContinueButton: React.FC<Props> = ({ disabled, onClick }) => {
  const handleClick = () => {
    if (onClick && !disabled) {
      onClick()
    }
  }

  return (
    <button
      type="submit"
      disabled={disabled}
      onClick={handleClick}
      className={clsx(
        'mx-auto flex h-20 w-20 cursor-pointer items-center justify-center rounded-full bg-[#222222] drop-shadow-xl transition-opacity',
        disabled ? 'opacity-60' : 'opacity-100',
      )}
    >
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20.125 10.0002L12 17.8479" stroke="white" strokeWidth="3" strokeLinecap="round" />
        <path d="M20.0938 10L11.0937 2" stroke="white" strokeWidth="3" strokeLinecap="round" />
        <path d="M2 9.87805L19.9389 9.87805" stroke="white" strokeWidth="3" strokeLinecap="round" />
      </svg>
    </button>
  )
}
