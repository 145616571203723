import { Navigate, useLoaderData } from 'react-router-dom'
import { RoutePaths } from 'routes'
import { IsPremiumEntitledAndIsSignedIn } from 'types'

export const RedirectRoute = () => {
  const { isPremiumEntitled, isSignedIn } = useLoaderData() as IsPremiumEntitledAndIsSignedIn

  const navigateTo = isSignedIn
    ? isPremiumEntitled
      ? RoutePaths.ThankYou
      : RoutePaths.Plans
    : RoutePaths.Drivers

  return <Navigate to={navigateTo} replace />
}
