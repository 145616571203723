import { CTAButton } from 'components/CTAButton'
import { formatPrice } from 'helpers'
import getCurrencyAndPriceInCents from 'helpers/getCurrencyAndPriceInCents'
import getIntroPriceInCents from 'helpers/getIntroPriceInCents'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'
import { StripeCoupon, StripePrice } from 'types'

type MonthlyPlanProps = {
  introPriceCoupon: StripeCoupon | undefined
  isLoading: boolean
  locale: string
  monthlyPrice: StripePrice
  userCurrency: string
}

export default function MonthlyPlan({
  introPriceCoupon,
  isLoading,
  locale,
  monthlyPrice,
  userCurrency,
}: MonthlyPlanProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { currency: monthlyPriceCurrency, priceInCents: monthlyPriceInCents } =
    getCurrencyAndPriceInCents({
      stripePrice: monthlyPrice,
      userCurrency,
    })

  const monthlyIntroPriceInCents = getIntroPriceInCents({
    introPriceCoupon,
    priceInCents: monthlyPriceInCents,
  })

  return (
    <div className="rounded-3xl border-2 border-[#9699AE40] bg-white p-8 text-center">
      <p className="text-4xl font-bold">1</p>

      <p className="text-lg uppercase">{t('plans.month')}</p>

      {isLoading ? (
        <div className="mb-7 mt-7 flex flex-col items-center justify-center">
          <div className="h-6 w-3/5 animate-pulse rounded-lg bg-[#BBBFCB]" />
        </div>
      ) : (
        <div className="mt-6 text-2xl">
          {monthlyIntroPriceInCents && (
            <s className="mb-6">
              {formatPrice({
                currency: monthlyPriceCurrency,
                locale,
                priceInCents: monthlyPriceInCents,
              })}{' '}
              / {t('plans.month')}
            </s>
          )}

          <p className="mb-6">
            {formatPrice({
              currency: monthlyPriceCurrency,
              locale,
              priceInCents: monthlyIntroPriceInCents || monthlyPriceInCents,
            })}{' '}
            / {t('plans.month')}
          </p>
        </div>
      )}

      <CTAButton
        text={t('plans.cta')}
        onClick={() =>
          navigate(RoutePaths.Checkout, {
            state: {
              couponId: introPriceCoupon?.id,
              currency: monthlyPriceCurrency,
              duration: 1,
              priceId: monthlyPrice.id,
            },
          })
        }
      />

      <p className="mt-4">{t('plans.cancel')}</p>

      <p className="mt-4 font-bold">{t('plans.guarantee')}</p>
    </div>
  )
}
