import { Navigate, Outlet, useLoaderData, useLocation } from 'react-router-dom'
import { RoutePaths } from 'routes'
import { IsSignedIn } from 'types'

export const ProtectedRoute = () => {
  const location = useLocation()
  const { isSignedIn } = useLoaderData() as IsSignedIn

  return isSignedIn ? (
    <Outlet />
  ) : (
    <Navigate state={{ from: location.pathname }} to={RoutePaths.Login} />
  )
}
