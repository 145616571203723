import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContinueButton } from 'components/ContinueButton'
import { RoutePaths } from 'routes'

export const FeedbackFeeling = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleContinue = () => {
    navigate(RoutePaths.StatementAnxious)
  }

  return (
    <div className="fixed bottom-0 left-[10%] right-[10%] top-0 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center space-y-8">
        <span className="text-7xl">🛟</span>
        <h1
          className="text-center text-2xl font-bold"
          dangerouslySetInnerHTML={{
            __html: t('feedback_feeling.text', { interpolation: { escapeValue: false } }),
          }}
        />
      </div>

      <div className="fixed bottom-8 mb-4">
        <ContinueButton onClick={handleContinue} disabled={false} />
      </div>
    </div>
  )
}
