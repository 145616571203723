import { FC } from 'react'

interface HttpsRedirectProps {
  disabled?: boolean
}

const isLocalHost = (hostname: string): boolean =>
  !!(
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  )

interface HttpsRedirectProps {
  disabled?: boolean
  children?: React.ReactNode
}

const HttpsRedirect: FC<HttpsRedirectProps> = ({ disabled, children }) => {
  if (
    !disabled &&
    typeof window !== 'undefined' &&
    window.location &&
    window.location.protocol === 'http:' &&
    !isLocalHost(window.location.hostname)
  ) {
    window.location.href = window.location.href.replace(/^http(?!s)/, 'https')
    return null
  }

  return <>{children}</>
}

export default HttpsRedirect
