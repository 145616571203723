import { loadStripe, PaymentIntent } from '@stripe/stripe-js'
import { REVENUE_CAT_API_BASE_URL } from 'api/revenueCat'
import { supabaseClient } from 'api/supabase'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { RoutePaths } from 'routes'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PK)

export default function PaymentConfirmation() {
  const [isLoading, setIsLoading] = useState(true)
  const [paymentError, setPaymentError] = useState<string | null>(null)
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null)
  const [userId, setUserId] = useState<string | null>(null)

  const navigate = useNavigate()
  const supabase = supabaseClient
  const { t } = useTranslation()

  const searchParams = new URLSearchParams(window.location.search)

  const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret')
  const priceId = searchParams.get('price_id')
  const subscriptionId = searchParams.get('subscription_id')

  const navigateToCheckout = () => {
    navigate(`${RoutePaths.Checkout}?price_id=${priceId}`)
  }

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession()

      if (error) {
        console.error(error)
      } else {
        const user = data?.session?.user

        if (user?.id) {
          setUserId(user.id)
        }
      }
    }

    fetchSession()
  }, [supabase])

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      const stripe = await stripePromise

      if (!paymentIntentClientSecret || !stripe) {
        return
      }

      try {
        const paymentIntentResult = await stripe.retrievePaymentIntent(paymentIntentClientSecret)

        if (paymentIntentResult.error) {
          console.error(paymentIntentResult.error)
          return
        }

        setPaymentIntent(paymentIntentResult.paymentIntent)
      } catch (error) {
        setPaymentError(t('checkout.paymentStatus.failure'))
      } finally {
        setIsLoading(false)
      }
    }

    fetchPaymentIntent()
  }, [paymentIntentClientSecret, t])

  useEffect(() => {
    const handlePaymentIntentStatus = async () => {
      if (paymentIntent && userId) {
        const { status } = paymentIntent

        if (status === 'succeeded') {
          await postSubscriptionToRevenueCat()
          await trackFbPurchase(paymentIntent.amount, paymentIntent.currency)
        } else if (status === 'requires_payment_method') {
          setPaymentError(t('checkout.paymentStatus.paymentMethodRequired'))
        } else {
          setPaymentError(t('checkout.paymentStatus.failure'))
        }
      }
    }

    const postSubscriptionToRevenueCat = async () => {
      try {
        const response = await fetch(`${REVENUE_CAT_API_BASE_URL}/receipts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Platform': 'stripe',
            Authorization: `Bearer ${import.meta.env.VITE_REVENUECAT_API_KEY}`,
          },
          body: JSON.stringify({
            app_user_id: userId,
            fetch_token: subscriptionId,
          }),
        })

        if (!response.ok) {
          throw new Error('Failed to post subscription to RevenueCat')
        } else {
          console.log('Subscription posted to RevenueCat')
        }
      } catch (error) {
        console.error(error)
      }
    }

    const trackFbPurchase = async (value: number, currency: string) => {
      return new Promise<void>((resolve, reject) => {
        if (fbq) {
          fbq('track', 'Purchase', {
            value: value / 100,
            currency: currency.toUpperCase(),
          })
          resolve()
        } else {
          reject(new Error('Facebook pixel not available'))
        }
      })
    }

    handlePaymentIntentStatus()
  }, [paymentIntent, subscriptionId, t, userId])

  if (!paymentIntentClientSecret || !priceId || !subscriptionId) {
    return <Navigate replace to={RoutePaths.Checkout} />
  } else if (isLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <ClipLoader className="" color="#222222" size={50} speedMultiplier={0.8} />
      </div>
    )
  } else if (paymentError) {
    return (
      <div className="flex flex-col">
        <div className="flex flex-col md:w-1/2 md:self-center">
          <h1 className="text-2xl font-bold">{t('paymentConfirmation.paymentError')}</h1>

          <p className="mt-8">{paymentError}</p>

          <p className="mt-2">
            <span onClick={navigateToCheckout} className="cursor-pointer font-bold text-blue-400">
              {t('paymentConfirmation.clickHere')}
            </span>{' '}
            {t('paymentConfirmation.returnToCheckout')}
          </p>
        </div>
      </div>
    )
  } else if (paymentIntent && paymentIntent.status === 'succeeded') {
    return <Navigate replace to={RoutePaths.ThankYou} />
  }
}
