import { Button } from 'components/Button'
import { ContinueButton } from 'components/ContinueButton'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'
import { Goal } from 'types'

export const Goals = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [goals, setGoals] = useState<Goal[]>([
    {
      color: 'pd-orange',
      emoji: '💡',
      id: '1',
      selected: false,
      text: t('goals.goal_1'),
    },
    {
      color: 'pd-yellow',
      emoji: '👟',
      id: '2',
      selected: false,
      text: t('goals.goal_2'),
    },
    {
      color: 'pd-peach',
      emoji: '🔨',
      id: '3',
      selected: false,
      text: t('goals.goal_3'),
    },
    {
      color: 'pd-purple',
      emoji: '🌱',
      id: '4',
      selected: false,
      text: t('goals.goal_4'),
    },
  ])

  const selectGoal = (goalId: string) => {
    setGoals((prevGoals) => {
      return prevGoals.map((goal) => {
        if (goal.id === goalId) {
          return {
            ...goal,
            selected: !goal.selected,
          }
        }
        return goal
      })
    })
  }

  const continueButtonDisabled = () => goals.filter(({ selected }) => selected).length == 0

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (continueButtonDisabled()) {
      return
    }

    navigate(RoutePaths.Feedback)
  }

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-4">
        <h1 className="mb-4 text-2xl font-bold">{t('goals.headline')}</h1>

        <h2 className="text-lg font-semibold text-[#BBBFCB]">{t('goals.subheadline')}</h2>
      </div>

      <form className="shrink-0 space-y-6" onSubmit={onFormSubmit}>
        {goals.map((goal) => (
          <Button
            color={goal.color}
            emoji={goal.emoji}
            key={goal.id}
            onClick={() => selectGoal(goal.id)}
            selected={goal.selected}
            text={goal.text}
          />
        ))}

        <ContinueButton disabled={continueButtonDisabled()} />
      </form>
    </div>
  )
}
