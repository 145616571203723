import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useNavigate } from "react-router-dom";
import RefundMultipleSubscriptionsDialog from "../../components/helpCenter/RefundMultipleSubscriptionsDialog";
import LayoutWhite from "../../components/LayoutWhite";
import { refundRevenueCatSubscriptions } from "../../helpers/supabase";
import getSubscriptionsLoader from "../../loaders/getSubscriptionsLoader";
import { RoutePaths } from "routes";
import { RevenueCatSubscription } from "supabase_types";

export default function RefundPage() {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [hasMultipleNonRefundedSubscriptions, setHasMultipleNonRefundedSubscriptions] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [hasNoNonRefundedSubscriptions, setHasNoNonRefundedSubscriptions] = useState(false);
  const [refundableSubscriptions, setRefundableSubscriptions] = useState<RevenueCatSubscription[]>([]);


  useEffect(() => {
    const fetchSubscriptions = async () => {
      const response = await getSubscriptionsLoader();
 

      if (typeof response === 'string') {
        navigate(response);
      } else {
        const { refundableSubscriptions, userEmail } = response;

        setRefundableSubscriptions(refundableSubscriptions)
        setUserEmail(userEmail)
        setHasMultipleNonRefundedSubscriptions(refundableSubscriptions.length > 1)
        setHasNoNonRefundedSubscriptions(refundableSubscriptions.length === 0)
      }
    }

    fetchSubscriptions()
  }, [navigate])

  const refundSubscriptions = async () => {
    const {
      activeAppStoreSubscriptionsCount,
      activePlayStoreSubscriptionsCount,
      canceledStripeSubscriptionsCount,
      refundedStripeSubscriptionsCount,
    } = await refundRevenueCatSubscriptions();

    navigate(RoutePaths.RefundCompleted, {
      replace: true,
      state: {
        activeAppStoreSubscriptionsCount,
        activePlayStoreSubscriptionsCount,
        canceledStripeSubscriptionsCount,
        refundedStripeSubscriptionsCount,
      },
    });
  };

  const handleClick = async () => {
    setIsSubmitting(true);

    if (hasMultipleNonRefundedSubscriptions) {
      setDisplayDialog(true);
    } else {
      await refundSubscriptions();
    }

    setIsSubmitting(false);
  };

  const labelClassName = "font-bold";
  const inputClassName =
    "w-full rounded-lg border-[#D9D9D9] bg-[#F0F0F0] focus:ring-0 py-4 px-2 focus:border-black";

  return (
    <>
      <LayoutWhite>
        <div className="shrink-0">
          <h1 className="font-bold">{t("pages.helpCenter.refund.heading")}</h1>
        </div>

        <div className="flex grow flex-col justify-center space-y-16">
          <form className="space-y-8">
            <div className="space-y-3">
              <label className={labelClassName}>
                {t("pages.helpCenter.shared.emailAddress")}
              </label>

              <input
                className={inputClassName}
                defaultValue={userEmail}
                readOnly
                type="email"
              />
            </div>

            <div className="space-y-3">
              <label className={labelClassName}>
                {t("pages.helpCenter.shared.subject")}
              </label>

              <input
                className={inputClassName}
                defaultValue={t("pages.helpCenter.requestRefund")}
                readOnly
              />
            </div>
          </form>

          {hasNoNonRefundedSubscriptions && (
            <div className="rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center">
              <p>{t("pages.helpCenter.shared.noActiveSubscription")}</p>
            </div>
          )}
        </div>

        <div className="shrink-0">
          <button
            disabled={hasNoNonRefundedSubscriptions || isSubmitting}
            onClick={handleClick}
            className="w-full rounded-3xl bg-black p-6 text-lg font-bold uppercase text-white hover:opacity-70 disabled:opacity-30"
            title="Request Cancelation"
          >
            {t("pages.helpCenter.refund.requestRefund")}
          </button>
        </div>
      </LayoutWhite>

      {displayDialog && (
        <RefundMultipleSubscriptionsDialog
          isSubmitting={isSubmitting}
          refundableSubscriptions={refundableSubscriptions}
          onClose={() => setDisplayDialog(false)}
          open
          refundSubscriptions={refundSubscriptions}
          setIsSubmitting={setIsSubmitting}
        />
      )}
    </>
  );
}
