import { getOrCreateCustomer } from 'api/revenueCat'
import { getUserId } from 'api/supabase'
import { RevenueCatSubscriber, RevenueCatSubscription } from 'types'

export const getIsPremiumEntitled = async (): Promise<boolean> => {
  const revenueCatSubscriber = await getRevenueCatSubscriber()

  if (!revenueCatSubscriber) return false

  const premiumEntitlement = revenueCatSubscriber?.entitlements?.['Premium']

  if (!premiumEntitlement) return false

  const expiresDate = premiumEntitlement.expires_date

  if (!expiresDate) return true

  return expiresDate > new Date().toISOString()
}

export const getRevenueCatSubscriber = async (): Promise<RevenueCatSubscriber | undefined> => {
  const userId = await getUserId()

  if (!userId) return

  const revenueCatCustomer = await getOrCreateCustomer(userId)

  if (!revenueCatCustomer) return

  return revenueCatCustomer?.subscriber
}

export const getRevenueCatSubscription = async (): Promise<RevenueCatSubscription | undefined> => {
  const revenueCatSubscriber = await getRevenueCatSubscriber()

  if (!revenueCatSubscriber) return

  const subscriptionId = revenueCatSubscriber?.entitlements?.['Premium']?.product_identifier

  return revenueCatSubscriber?.subscriptions?.[subscriptionId]
}
