import appleLogo from 'assets/apple-logo.png'
import googleLogo from 'assets/google-logo.png'
import clsx from 'classnames'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
  onClick: () => void
  provider: 'Apple' | 'Google'
}

export const SignInWithButton: React.FC<Props> = ({ className, onClick, provider }) => {
  const { t } = useTranslation()

  const logo = provider === 'Apple' ? appleLogo : googleLogo

  return (
    <button
      type="button"
      className={clsx(
        'inline-flex w-full justify-center space-x-2 rounded-full border border-[#BBBFCB] px-3 py-6 text-xl font-bold outline-[#BBBFCB] focus-visible:outline-none',
        className,
      )}
      onClick={onClick}
    >
      <img src={logo} alt={`${provider} logo`} className="h-6 w-6" />

      <span>{t('login.logInWith', { provider: provider })}</span>
    </button>
  )
}
