import * as Sentry from '@sentry/react'

export const REVENUE_CAT_API_BASE_URL = 'https://api.revenuecat.com/v1'

export const getOrCreateCustomer = async (appUserId: string | undefined) => {
  const response = await fetch(`${REVENUE_CAT_API_BASE_URL}/subscribers/${appUserId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${import.meta.env.VITE_REVENUECAT_API_KEY}`,
    },
  })

  const responseJson = await response.json()

  if (!response.ok) {
    Sentry.captureMessage(responseJson.message)
  }

  return responseJson
}
