import i18n, { LOCALES } from 'i18n'

export const useUserLocale = () => {
  const userLocale = window.navigator.language
  const languageWithoutRegion = userLocale.split('-')[0]
  const locale = LOCALES.includes(languageWithoutRegion) ? languageWithoutRegion : 'en'

  if (i18n.language !== locale) {
    i18n.changeLanguage(locale)
  }

  return locale
}
