import { Outlet } from 'react-router-dom'

export const Layout = () => {
  return (
    <div className="h-full min-h-dvh animate-change-bg from-[#F5E1EF] to-[#DFF8FC]">
      <div className="h-full min-h-dvh bg-gradient-to-b from-white to-transparent">
        <div className="container mx-auto flex h-full min-h-dvh max-w-5xl px-10 py-10">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
