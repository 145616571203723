import clsx from 'classnames'

interface Props {
  text: string
  onClick: () => void
  className?: string
}

export const CTAButton: React.FC<Props> = ({ text, onClick, className }) => {
  return (
    <button
      type="button"
      className={clsx(
        'inline-flex w-full justify-center rounded-full bg-[#222222] px-3 py-6 text-xl font-bold text-white shadow-[0_25px_30px_-10px_rgba(38,57,77,0.6)] focus-visible:outline-none',
        className,
      )}
      onClick={onClick}
    >
      {text}
    </button>
  )
}
