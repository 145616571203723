import { ContinueButton } from 'components/ContinueButton'
import { Input } from 'components/Input'
import { isPasswordValid } from 'helpers/validators'
import { useSupabase } from 'hooks/useSupabase'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { RoutePaths } from 'routes'

export const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState<string>('')
  const [passwordValid, setPasswordValid] = useState<boolean>(true)

  const navigate = useNavigate()
  const supabase = useSupabase()
  const { t } = useTranslation()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    })

    if (error) {
      if (error.message === 'New password should be different from the old password.') {
        toast(t('resetPassword.newPasswordShouldBeDifferent'), {
          type: 'error',
          position: 'bottom-center',
        })
      } else {
        throw new Error(error.message)
      }
    } else {
      toast(t('resetPassword.success'), {
        type: 'success',
        position: 'bottom-center',
      })

      setTimeout(() => {
        navigate(RoutePaths.Login)
      }, 5000)
    }
  }

  return (
    <>
      <div className="flex w-full flex-col space-y-8">
        <div className="basis-2/5 space-y-4">
          <h1 className="text-2xl font-bold">{t('resetPassword.headline')}</h1>

          <p className="text-lg font-semibold text-[#2222224D]">{t('resetPassword.text')}</p>
        </div>

        <form className="flex basis-3/5 flex-col justify-between space-y-6" onSubmit={handleSubmit}>
          <Input
            invalid={!passwordValid}
            onBlur={() => setPasswordValid(isPasswordValid(newPassword))}
            onChange={(event) => setNewPassword(event?.target.value)}
            placeholder={t('resetPassword.newPassword')}
            required
            type="password"
            value={newPassword}
          />

          <ContinueButton disabled={!newPassword || !isPasswordValid(newPassword)} />
        </form>
      </div>

      <ToastContainer />
    </>
  )
}
