import { StripeCoupon } from 'types'

export default function getIntroPriceInCents({
  introPriceCoupon,
  priceInCents,
}: {
  introPriceCoupon: StripeCoupon | undefined
  priceInCents: number
}): number | undefined {
  if (!introPriceCoupon) return

  const percentOff = introPriceCoupon?.percent_off

  if (!percentOff) return

  return priceInCents * (1 - percentOff / 100)
}
