import * as Sentry from '@sentry/react'
import { ContinueButton } from 'components/ContinueButton'
import { Input } from 'components/Input'
import { TextModal } from 'components/TextModal'
import { isEmailValid } from 'helpers/validators'
import { useSupabase } from 'hooks/useSupabase'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from 'routes'

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('')
  const [emailValid, setEmailValid] = useState<boolean>(true)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const supabase = useSupabase()
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event?.target.value)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}${RoutePaths.ResetPassword}`,
    })

    if (error) {
      Sentry.captureException(error)
    }

    setModalOpen(true)
  }

  return (
    <>
      <div className="flex w-full flex-col space-y-8">
        <div className="basis-2/5 space-y-4">
          <h1 className="text-2xl font-bold">{t('forgotPassword.headline')}</h1>

          <p className="text-lg font-semibold text-[#2222224D]">{t('forgotPassword.text')}</p>
        </div>

        <form className="flex basis-3/5 flex-col justify-between space-y-6" onSubmit={handleSubmit}>
          <Input
            invalid={!emailValid}
            onBlur={() => setEmailValid(isEmailValid(email))}
            onChange={handleChange}
            placeholder={t('common.email')}
            required
            type="email"
            value={email}
          />

          <ContinueButton disabled={!email || !isEmailValid(email)} />
        </form>
      </div>

      {modalOpen && <TextModal open setOpen={setModalOpen} text={t('forgotPassword.modalText')} />}
    </>
  )
}
