import { CTAButton } from 'components/CTAButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

export const StatementAnxious = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col items-center justify-between">
      <div className="space-y-20">
        <div className="space-y-4">
          <h1 className="text-2xl font-bold">{t('statementAnxious.headline')}</h1>
        </div>
        <div className="flex flex-col items-center space-y-4 bg-white p-8 shadow-xl">
          <span className="text-center text-3xl">😰</span>
          <h3
            className="text-center text-2xl italic"
            dangerouslySetInnerHTML={{
              __html: t('statementAnxious.text', { interpolation: { escapeValue: false } }),
            }}
          />
        </div>
      </div>
      <div className="mb-4 mt-6 flex space-x-8">
        <CTAButton
          text={t('statementAnxious.no')}
          onClick={() => navigate(RoutePaths.StatementRegrets)}
          className="px-9"
        />

        <CTAButton
          text={t('statementAnxious.yes')}
          onClick={() => navigate(RoutePaths.StatementRegrets)}
          className="px-9"
        />
      </div>
    </div>
  )
}
