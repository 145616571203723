import * as Sentry from "@sentry/react";
import { RevenueCatCustomer } from "supabase_types";

interface RevenueCatSubscriberResponse extends Response {
  json(): Promise<RevenueCatCustomer>;
}

export async function getOrCreateRevenueCatCustomer(
  appUserId: string,
): Promise<RevenueCatCustomer> {
  const response: RevenueCatSubscriberResponse = await fetch(
    `https://api.revenuecat.com/v1/subscribers/${appUserId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_REVENUECAT_API_KEY}`,
      },
    },
  );

  if (!response.ok) {
    Sentry.captureMessage(
      `Getting or creating RevenueCat customer failed with app_user_id=${appUserId}`,
    );
  }

  return await response.json();
}
