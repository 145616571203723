interface Props {
  currency: string
  priceInCents: number
  locale: string
}

export const formatPrice = ({ currency, priceInCents, locale }: Props) => {
  const price = +(Math.floor(priceInCents) / 100).toFixed(2)

  return Intl.NumberFormat(locale, {
    currency: currency,
    style: 'currency',
  }).format(price)
}

export const formatDateString = (dateString: string | undefined) => {
  if (dateString === undefined) return ''

  const date = new Date(dateString)

  return new Intl.DateTimeFormat().format(date)
}
