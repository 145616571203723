import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { I18nextProvider } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'
import HttpsRedirect from 'providers/https_redirect'
import { SpeedInsights } from '@vercel/speed-insights/react'

import i18n from 'i18n'

import FacebookPixel from 'components/FacebookPixel'
import { router } from 'components/Router'

import { useUserLocale } from 'hooks/useUserLocale'

const queryClient = new QueryClient()

export default function App() {
  useUserLocale()

  return (
    <HttpsRedirect>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18n}>
            <FacebookPixel pixelId={import.meta.env.VITE_FACEBOOK_PIXEL_ID} />
            <RouterProvider router={router()} />
            <SpeedInsights />
          </I18nextProvider>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </HttpsRedirect>
  )
}
