export enum RoutePaths {
  Root = '/',
  Drivers = '/drivers',
  Goals = '/goals',
  Feedback = '/feedback',
  LastMinute = '/last-minute',
  Distractions = '/distractions',
  PartOfDay = '/part-of-day',
  Review = '/review',
  TimeOfDay = '/time-of-day',
  Desire = '/desire',
  Feeling = '/feeling',
  FeedbackFeeling = '/feedback-feeling',
  StatementAnxious = '/statement-anxious',
  StatementRegrets = '/statement-regrets',
  Habits = '/habits',
  Ready = '/ready',
  Password = '/password',
  Email = '/email',
  BuildingPlan = '/building-plan',
  Plans = '/plans',
  Checkout = '/checkout',
  PaymentConfirmation = '/payment-confirmation',
  ThankYou = '/thank-you',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Login = '/login',
  HelpCenter = '/help',
  Cancelation = '/help/cancelation',
  CancelationApple = '/help/cancelation/apple',
  CancelationGoogle = '/help/cancelation/google',
  CancelationCompleted = '/help/cancelation/completed',
  Refund = '/help/refund',
  RefundApple = '/help/refund/apple',
  RefundGoogle = '/help/refund/google',
  RefundCompleted = '/help/refund/completed',
}
