import clsx from 'classnames'

interface Props {
  className?: string
}

export const CheckIcon: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={clsx(
        'flex h-[30px] w-[30px] items-center justify-center rounded-full border border-x-[1.8px] border-y-[1.8px] border-[#222222] bg-[#222222]',
        className,
      )}
    >
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.219 11.882C6.14124 11.8979 6.06096 11.8976 5.98277 11.8811C5.90457 11.8647 5.82998 11.8324 5.76327 11.7861L1.21684 8.6354C1.08208 8.54201 0.987526 8.39733 0.953984 8.23318C0.937376 8.1519 0.936245 8.06829 0.950657 7.98711C0.965069 7.90594 0.994742 7.8288 1.03798 7.76009C1.08122 7.69138 1.13718 7.63245 1.20266 7.58667C1.26814 7.54088 1.34187 7.50914 1.41962 7.49325C1.57666 7.46116 1.74059 7.4956 1.87535 7.58898L5.91366 10.3876L11.5792 1.38442C11.667 1.24681 11.8041 1.15193 11.9606 1.12049C12.1171 1.08904 12.2803 1.12359 12.4145 1.21658C12.5487 1.30958 12.643 1.45347 12.6769 1.61685C12.7108 1.78023 12.6815 1.94981 12.5954 2.08859L6.60063 11.6151C6.55741 11.6838 6.50146 11.7427 6.43598 11.7885C6.3705 11.8343 6.29676 11.8661 6.219 11.882Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    </div>
  )
}
