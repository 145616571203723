import { supabaseClient } from 'api/supabase'
import perfectDayLogoUrl from 'assets/perfect-day-logo.png'
import clsx from 'classnames'
import SubscriptionInfoModal from 'components/SubscriptionInfoModal'
import { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

export const ThankYou = () => {
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState<boolean>(true)
  const [downloadLink, setDownloadLink] = useState<string>(
    import.meta.env.VITE_APPLE_APP_STORE_LINK,
  )

  const [email, setEmail] = useState<string | null>(null)
  const [isSubscriptionInfoModalOpen, setIsSubscriptionInfoModalOpen] = useState(false)

  const supabase = supabaseClient
  const { t } = useTranslation()

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession()

      if (error) {
        console.error(error)
      } else {
        const user = data?.session?.user

        if (user?.email) {
          setEmail(user.email)
        }
      }
    }

    fetchSession()
  }, [supabase])

  useEffect(() => {
    if (isAndroid) {
      setDownloadLink(import.meta.env.VITE_GOOGLE_PLAY_STORE_LINK)
    } else if (isIOS) {
      setDownloadLink(import.meta.env.VITE_APPLE_APP_STORE_LINK)
    }
  }, [])

  return (
    <>
      <div className="flex w-full flex-col space-y-8">
        <div className="shrink-0 space-y-11">
          <div className="flex items-center justify-between space-x-4">
            <img src={perfectDayLogoUrl} alt="Perfect Day logo" className="h-12 w-10" />

            <button
              onClick={() => setIsSubscriptionInfoModalOpen(true)}
              className="truncate font-bold"
            >
              {t('thankYou.subscriptionInfo')}
            </button>
          </div>

          <h1 className="text-2xl font-bold">{t('thankYou.headline')}</h1>
        </div>

        <div className="flex grow flex-col justify-center space-y-11 p-3">
          <div className="flex items-center gap-x-4">
            <span className="text-2xl">1️⃣</span>

            <p>{t('thankYou.stepOne')}</p>
          </div>

          <div className="flex items-center gap-x-4">
            <span className="text-2xl">2️⃣</span>

            <p>
              {t('thankYou.stepTwo')} <span className="font-bold">{email}</span>
            </p>
          </div>

          <div className="flex items-center gap-x-4">
            <span className="text-2xl">❗️</span>

            <p>{t('thankYou.caution')}</p>
          </div>
        </div>

        <div className="">
          <div className="flex flex-row items-center gap-x-4">
            <input
              className="h-5 w-5"
              id="hint"
              onClick={() => setDownloadButtonDisabled(!downloadButtonDisabled)}
              type="checkbox"
            />

            <label htmlFor="hint">{t('thankYou.gotIt')}</label>
          </div>

          <button
            disabled={downloadButtonDisabled}
            className={clsx(
              'mt-8 w-full rounded-3xl bg-[#222222] px-6 py-4 text-xl font-bold text-white shadow-2xl drop-shadow-2xl transition-opacity',
              downloadButtonDisabled ? 'opacity-60' : 'opacity-100',
            )}
            onClick={() => window.open(downloadLink, '_blank')}
          >
            {t('thankYou.downloadTheApp')}
          </button>
        </div>
      </div>

      {isSubscriptionInfoModalOpen && (
        <SubscriptionInfoModal setOpen={setIsSubscriptionInfoModalOpen} />
      )}
    </>
  )
}
