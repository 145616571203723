import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { CheckoutForm } from 'components/CheckoutForm'
import { LoadingSpinner } from 'components/LoadingSpinner'

import { useSupabase } from 'hooks/useSupabase'
import { RoutePaths } from 'routes'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PK)

export const Checkout = () => {
  const [subscriptionId, setSubscriptionId] = useState('')
  const [email, setEmail] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [stripeAmount, setStripeAmount] = useState(null)
  const [stripeAmountOriginal, setStripeAmountOriginal] = useState(null)
  const [stripeCurrency, setStripeCurrency] = useState(null)
  const [options, setOptions] = useState({
    appearance: {
      theme: 'flat',
    },
  } as StripeElementsOptions)

  const location = useLocation()
  const supabase = useSupabase()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { currency, couponId, duration, priceId } = location.state

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession()

      if (error) {
        console.error(error)
      } else {
        const email = data?.session?.user?.email
        if (email) {
          setEmail(email)
        }
      }
    }

    fetchSession()
  }, [supabase])

  useEffect(() => {
    if (!priceId) {
      navigate(RoutePaths.Plans)
    }

    const createStripeSubscription = async () => {
      if (!email) {
        return
      }

      const { data, error } = await supabase.functions.invoke('create-stripe-subscription', {
        body: { coupon_id: couponId, currency, price_id: priceId, email: email },
      })

      if (error) {
        console.error(error)
        return
      }

      const {
        amount: stripeAmount,
        amount_original: stripeAmountOriginal,
        currency: stripeCurrency,
        client_secret: clientSecret,
        subscription_id: subscriptionId,
      } = data

      setOptions({
        ...options,
        clientSecret: clientSecret,
      })
      setStripeAmount(stripeAmount)
      setStripeAmountOriginal(stripeAmountOriginal)
      setStripeCurrency(stripeCurrency)
      setSubscriptionId(subscriptionId)
      trackFbInitiateCheckout(stripeAmount, stripeCurrency)
      setIsLoading(false)
    }

    createStripeSubscription()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponId, currency, email, navigate, priceId])

  const trackFbInitiateCheckout = (amount: number, currency: string) => {
    if (fbq) {
      fbq('track', 'InitiateCheckout', {
        value: amount / 100,
        currency: currency.toUpperCase(),
      })
    }
  }

  if (isLoading || !stripeAmount || !stripeAmountOriginal || !stripeCurrency) {
    return (
      <div className="absolute left-[50%] top-1/3 -translate-x-[50%] rounded-2xl bg-[#222222] p-3">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <div className="flex w-full flex-col justify-between space-y-8">
        <h1 className="text-2xl font-bold">{t('checkout.selectPaymentMethod')}</h1>

        <div className="space-y-5 sm:space-y-8">
          <div className="space-y-4">
            <h3 className="font-semibold">{t('checkout.secureHeader')}</h3>

            <p className="leading-4">{t('checkout.secureText')}</p>
          </div>

          <div className="space-y-4">
            <h3 className="font-semibold">{t('checkout.moneyBackHeader')}</h3>

            <p className="leading-4">{t('checkout.moneyBackText')}</p>
          </div>
        </div>

        <CheckoutForm
          amount={stripeAmount}
          amountOriginal={stripeAmountOriginal}
          currency={stripeCurrency}
          priceId={priceId}
          subscriptionId={subscriptionId}
          duration={duration}
        />
      </div>
    </Elements>
  )
}
