import { createClient } from '@supabase/supabase-js'

export const getUserId = async () => {
  const { data } = await supabaseClient.auth.getSession()
  return data?.session?.user?.id
}

export const supabaseClient = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_KEY,
)
