import { useSuspenseQuery } from '@tanstack/react-query'

export const useUserCurrency = () => {
  const response = useSuspenseQuery({
    queryKey: ['currency'],
    queryFn: async () => (await fetch('https://api.asanarebel.com/api/v1/geolocation')).json(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  const currency = response.data?.currency

  return {
    userCurrency: currency,
  }
}
