import { ContinueButton } from 'components/ContinueButton'
import { Input } from 'components/Input'
import { isPasswordValid } from 'helpers/validators'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { RoutePaths } from 'routes'

export const Password = () => {
  const [password, setPassword] = useState('')
  const [, setPersistedPassword] = useLocalStorage('password', '')

  const navigate = useNavigate()
  const { t } = useTranslation()

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const storePasswordAndNavigate = () => {
    if (password && isPasswordValid(password)) {
      setPersistedPassword(password)
      navigate(RoutePaths.Email)
    }
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isPasswordValid(password)) {
      return
    }

    storePasswordAndNavigate()
  }

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="basis-2/5 space-y-4">
        <h1 className="text-2xl font-bold">{t('password.headline')}</h1>

        <h2 className="text-lg font-semibold text-[#BBBFCB]">{t('password.subheadline')}</h2>
      </div>

      <form className="flex basis-3/5 flex-col justify-between space-y-6" onSubmit={onFormSubmit}>
        <Input
          showEye
          type="password"
          placeholder={t('password.placeholder')}
          value={password}
          onChange={onPasswordChange}
        />

        <ContinueButton disabled={!isPasswordValid(password)} />
      </form>
    </div>
  )
}
