import { Button } from 'components/Button'
import { ContinueButton } from 'components/ContinueButton'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'

type Habit = {
  id: string
  color: 'pd-orange' | 'pd-yellow' | 'pd-peach' | 'pd-blue' | 'pd-purple'
  emoji: string
  text: string
  selected: boolean
}

export const Habits = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [habits, setHabits] = useState<Habit[]>([
    { id: '1', color: 'pd-orange', emoji: '📱', text: t('habits.habits_1'), selected: false },
    { id: '2', color: 'pd-yellow', emoji: '🍟', text: t('habits.habits_2'), selected: false },
    { id: '3', color: 'pd-peach', emoji: '🥱', text: t('habits.habits_3'), selected: false },
    { id: '4', color: 'pd-blue', emoji: '🥃', text: t('habits.habits_4'), selected: false },
    { id: '5', color: 'pd-purple', emoji: '🚬', text: t('habits.habits_5'), selected: false },
  ])

  const selectHabit = (habitId: string) => {
    setHabits((prevHabits) =>
      prevHabits.map((habit) => {
        if (habit.id === habitId) {
          return { ...habit, selected: !habit.selected }
        }
        return habit
      }),
    )
  }

  const continueButtonDisabled = () => habits.filter(({ selected }) => selected).length === 0

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (continueButtonDisabled()) {
      return
    }
    navigate(RoutePaths.Ready)
  }

  return (
    <div className="flex w-full flex-col space-y-8">
      <div className="grow space-y-4">
        <h1 className="text-2xl font-bold">{t('habits.headline')}</h1>
      </div>

      <form className="shrink-0 space-y-6" onSubmit={onFormSubmit}>
        {habits.map((habit) => (
          <Button
            color={habit.color}
            emoji={habit.emoji}
            key={habit.id}
            onClick={() => selectHabit(habit.id)}
            selected={habit.selected}
            text={habit.text}
          />
        ))}

        <ContinueButton disabled={continueButtonDisabled()} />
      </form>
    </div>
  )
}
